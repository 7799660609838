<template>
	<div
		class="full-height"
	>
		<h6>{{ program.name }}</h6>

		<div
			class="bg-white pa-10 box"
		>
			정산기준
			<select
				v-model="search.year"
				class="pa-5-10 bg-white"
				@change="getSearch(1)"
			>
				<option
					v-for="year in year_items"
					:key="'year_' + year"
				>{{ year }}</option>
			</select>
		</div>

		<div
			class="mt-10 box-ddd"
		>
			<div
				class="pa-10 bg-identify"
			>
				{{ search.date }}
			</div>
			<div class=" bg-white justify-space-between">
				<div class="pa-20 flex-1 border-right bg-icon-round-count">
					<div class="color-identify">정산 건수</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary.totalCount }}</div>
				</div>

				<div class="pa-20 flex-1 border-right bg-icon-paper">
					<div class="color-identify">결제 금액</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary.totalApprovalAmount  }}</div>
				</div>

				<div class="pa-20 flex-1 border-right bg-icon-calc">
					<div class="color-identify">정산 금액</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary.totalCalAmount  }}</div>
				</div>

				<div class="pa-20 flex-1 border-right bg-icon-round-count">
					<div class="color-identify">수수료(부가세 포함)</div>
					<div class="mt-10 font-weight-bold size-px-16"> {{ summary.totalFeeVat }}</div>
				</div>
			</div>
		</div>

		<div class="mt-10 pa-10 box-ddd bg-white full-height flex-column overflow-y-auto">
			<div class="justify-space-between">

				<div
				>
					<select
						v-if="false"
						class="pa-5-10 mr-10 size-px-12"
						v-model="item_confirm.approval"
						ref="settlement_status"
					>
						<option value="">승인여부</option>
						<option
							v-for="(status, index) in codes.settlement_confirm_state"
							:key="'approval_' + index"
							:value="status.code"
						>{{ status.name }}</option>
					</select>
					<button
						v-if="false"
						class="bg-identify pa-4-10 mr-10 vertical-middle"
						@click="isApproval"
					>승인상태 변경</button>

					<select
						v-show="search.type == 1"
						class="pa-5-10 mr-10 size-px-12"
						v-model="item_confirm.state"
						ref="settlement_status"
					>
						<option
							v-for="(status, index) in codes.settlement_payment_state"
							:key="'status_' + index"
							:value="status.code"
						>{{ status.name }}</option>
					</select>
					<button
						v-show="search.type == 1"
						class="bg-identify pa-4-10 mr-10 vertical-middle"
						@click="isConfirm"
					>지급상태 변경</button>
				</div>
				<div>
					<button
						class="box mr-10 pa-4-10 size-px-12"
						@click="toExcel"
					><v-icon small class="color-green ">mdi mdi-file-excel</v-icon> <span class="vertical-middle">엑셀 다운로드</span></button>
					<select
						class="pa-5-10 mr-10 size-px-12"
						v-model="search.size"
						@change="getSearch()"
					>
						<option
							v-for="cnt in codes.list_cnt"
							:key="'cnt_' + cnt"
							:value="cnt"
						>{{ cnt }} 건씩 보기</option>
					</select>
				</div>

			</div>
			<table
				v-if="items.length > 0"
				class="mt-10 table table-even top-line-identify"
			>
				<colgroup>
					<col width="60px" />
					<col width="100px" />
					<col width="100px" />
					<col width="100px" />
					<col width="80px" />

					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />
					<col width="auto" />

					<col width="100" />
					<col width="80" />
					<col width="100px" />

				</colgroup>
				<thead>
				<tr>
					<th>NO</th>
					<th>정산 시작일</th>
					<th>정산 종료일</th>
					<th>정산 기준월</th>

					<th>수수료율</th>
					<th>정산건수</th>
					<th>승인금액</th>
					<th>수수료</th>
					<th>부가세</th>

					<th>정산금액</th>
					<th>최종지급금액</th>
					<th>지급여부</th>
					<th>상세</th>
				</tr>
				</thead>
				<tbody>
				<tr
					v-for="(item, index) in item_list"
					:key="'list_' + index"
				>
					<td>{{ item.idx }}</td>
					<td>{{ item.fromDate | transDate}}</td>
					<td>{{ item.endDate }}</td>
					<td>{{ item.calDate }}</td>

					<td>{{ item.fee }}</td>
					<td>
						<template
							v-for="(type, sub_index) in codes.settlement_agency_type"
						>
							<div
								v-if="sub_index > 0"
								:key="'count_type_' + index + '_' + sub_index"
								class="justify-space-between"
							><span>{{ type.name }}</span> {{ item.count[type.value]}}</div>
						</template>
					</td>
					<td>
						<template
							v-for="(type, sub_index) in codes.settlement_agency_type"
						>
							<div
								v-if="sub_index > 0"
								:key="'approval_type_' + index + '_' + sub_index"
								class="justify-space-between"
							><span>{{ type.name }}</span> {{ item.approvalAmount[type.value]}}</div>
						</template>
					</td>
					<td>
						<template
							v-for="(type, sub_index) in codes.settlement_agency_type"
						>
							<div
								v-if="sub_index > 0"
								:key="'fee_type_' + index + '_' + sub_index"
								class="justify-space-between"
							><span>{{ type.name }}</span> {{ item.feeAmount[type.value]}}</div>
						</template>
					</td>

					<td>
						<template
							v-for="(type, sub_index) in codes.settlement_agency_type"
						>
							<div
								v-if="sub_index > 0"
								:key="'tax_type_' + index + '_' + sub_index"
								class="justify-space-between"
							><span>{{ type.name }}</span> {{ item.tax[type.value]}}</div>
						</template>
					</td>

					<td>
						<template
							v-for="(type, sub_index) in codes.settlement_agency_type"
						>
							<div
								v-if="sub_index > 0"
								:key="'cal_type_' + index + '_' + sub_index"
								class="justify-space-between"
							><span>{{ type.name }}</span> {{ item.calAmount[type.value]}}</div>
						</template>
					</td>

					<td>{{ item.amount }}</td>
					<td :class="'color-' + item.state_color ">{{ item.state_name }}</td>
					<td>
						<button
							class="bg-identify pa-5-10 size-px-12"
							@click="toDetail(item)"
						>상세보기</button>
					</td>
				</tr>
				</tbody>
			</table>

			<div
				v-else
				class="full-height flex-column justify-center mt-10 top-line-identify"
			>
				<div class="text-center">
					<v-icon
						class="size-px-48 "
					>mdi mdi-cloud-off-outline</v-icon>
					<br/>
					<br/>
					<div class="font-weight-bold size-px-24">No Data</div>
				</div>
			</div>

			<Pagination
				:program="program"
				:align="'center'"
				:options="search"

				class="mt-auto"
			></Pagination>

		</div>

		<Excel
			v-if="is_excel"
			:excel_data="excel_data"
			:date="date"

			@finish="close"
		></Excel>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:top="true"
			:bottom="true"

			title="정산 승인 상태 변경"
			width="400px"
			content_class="ptb-30"

			@close="close"
			@click="doApproval"
			@cancel="close"
		>
		</Modal>

		<Modal
			:is_modal="is_modal3"
			:option="modal_option3"
			:top="true"
			:bottom="true"

			title="정산 지급 상태 변경"
			width="400px"
			content_class="ptb-30"

			@close="close"
			@click="doConfirm"
			@cancel="close"
		>
			<div
				slot="modal-content"
				class="pa-10"
			>
				<div class="text-left">{{ modal_option3.content }}</div>
				<textarea
					v-modal="item_confirm.reason"
					placeholder="지급 보류시 사유를 반드시 작성해 주세요"
					class="mt-30"
				></textarea>
			</div>
		</Modal>

		<Modal
			:is_modal="is_modal2"
			:option="modal_option2"
			:top="true"

			title="마이 정산 상세 내역"
			class="full-height"
			width="1400px"
			height="850px"

			@close="close"

		>
			<SettlementMypageDetail
				:item="item"
				:type="search.type"

				slot="modal-content"

				@setNotify="setNotify"
				@onLoading="$emit('onLoading')"
				@offLoading="$emit('offLoading')"
			></SettlementMypageDetail>
		</Modal>
	</div>
</template>

<script>

import Pagination from "@/components/Pagination";
import Excel from "@/components/Excel";
import Modal from "@/components/Modal";

import SettlementMypageDetail from "@/view/Settlement/SettlementMypageDetail";

export default {
	name: 'SettlementAgencyList'
	,components: {SettlementMypageDetail, Modal, Excel, Pagination}
	,props: ['user', 'codes', 'Axios', 'date']
	,data: function(){
		return {
			program: {
				name: '마이 정산 내역'
				,top: true
				,title: true
				,bottom: false
			}
			,search: {
				page: this.$route.query.page ? this.$route.query.page : 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,total_count: 0
				,year: this.date.getThisYear()
			}
			,search_option: {

			}
			,is_excel: false
			,excel_data: {
				name: '마이 정산 내역'
				,header: [
					{ key: 0, name: '정산 시작일', column: 'fromDate'}
					,{ key: 0, name: '정산 종료일', column: 'endDate'}
					,{ key: 0, name: '정산 기준월', column: 'calDate'}
					,{ key: 0, name: '서비스 타입', column: 'service_name'}
					,{ key: 0, name: '정산 주기', column: 'carCycle'}
					,{ key: 0, name: '구분(영업점)', column: 'branchType'}
					,{ key: 0, name: '승인금액', column: 'approvalAmount'}
					,{ key: 0, name: '수수료율', column: 'fee'}
					,{ key: 0, name: '수수료', column: 'feeAmount'}
					,{ key: 0, name: '부가세VAT', column: 'tax'}
					,{ key: 0, name: '정산금액', column: 'calAmount'}
					,{ key: 0, name: '최종지급액', column: 'totalAmount'}
					,{ key: 0, name: '지급여부', column: 'state_name'}

				]
				,content: null
			}
			,items: []
			,item: {

			}

			,item_payment: []
			,summary: {}
			,is_modal: false
			,modal_option: {}
			,is_modal3: false
			,modal_option3: {}
			,is_modal2: false
			,modal_option2: {}
			,is_all: false
			,item_confirm: {
				state: ''
				, approval: ''
				, idx: []
			}
			,datePicker: false
		}
	}
	,computed: {
		item_list: function(){
			let self = this
			return this.items.filter(function(item){

				for(let i = 0; i < self.codes.settlement_confirm_state.length; i++){
					if(item.approvalState === self.codes.settlement_confirm_state[i].code){
						item.approval_name = self.codes.settlement_confirm_state[i].name
						item.approval_color = self.codes.settlement_confirm_state[i].color
						break
					}
				}
				for(let i = 0; i < self.codes.settlement_payment_state.length; i++){
					if(item.state == self.codes.settlement_payment_state[i].code){
						item.state_name = self.codes.settlement_payment_state[i].name
						item.state_color = self.codes.settlement_payment_state[i].color
						break
					}
				}

				return item
			})
		}
		,excel_list: function(){
			let self = this
			let list = []

			this.items.filter(function(item){

				for(let i = 0; i < self.codes.settlement_confirm_state.length; i++){
					if(item.approvalState === self.codes.settlement_confirm_state[i].code){
						item.approval_name = self.codes.settlement_confirm_state[i].name
						item.approval_color = self.codes.settlement_confirm_state[i].color
						break
					}
				}

				for(let i = 0; i < self.codes.settlement_payment_state.length; i++){
					if(item.state == self.codes.settlement_payment_state[i].code){
						item.state_name = self.codes.settlement_payment_state[i].name
						item.state_color = self.codes.settlement_payment_state[i].color
						break
					}
				}

				for(let i = 1; i < self.codes.settlement_agency_type.length; i++){

					let data = {
						service_name: self.codes.settlement_agency_type[i].name
						,fromDate: item.fromDate
						,endDate: item.endDate
						,calDate: item.calDate
						,branchName: item.branchName
						,fee: item.fee
						,totalAmount: item.calAmount[self.codes.settlement_agency_type[i].value] ? item.calAmount[self.codes.settlement_agency_type[i].value] : ''
						,state_name: item.state_name
						,approvalAmount: item.approvalAmount[self.codes.settlement_agency_type[i].value] ? item.approvalAmount[self.codes.settlement_agency_type[i].value] : ''
						,calAmount: item.calAmount[self.codes.settlement_agency_type[i].value] ? item.calAmount[self.codes.settlement_agency_type[i].value] : ''
						,count: item.count[self.codes.settlement_agency_type[i].value] ? item.count[self.codes.settlement_agency_type[i].value] : ''
						,feeAmount: item.feeAmount[self.codes.settlement_agency_type[i].value] ? item.feeAmount[self.codes.settlement_agency_type[i].value] : ''
						,tax: item.tax[self.codes.settlement_agency_type[i].value] ? item.tax[self.codes.settlement_agency_type[i].value] : ''
						,branchType: item.branchType
						,carCycle: item.carCycle
					}
					list.push(data)
				}
			})

			return list
		}
		,select_items: function(){
			let items = []
			this.items.filter(function(item){
				if(item.is_select){
					items.push(item.infoIdx)
				}
			})

			return items
		}
		,year_items: function(){
			let last = new Date()
			let year = last.getFullYear()
			let data = []

			for(let i = this.codes.start_year; i <= year; i++){
				data.push(i)
			}

			return data
		}
	}
	,methods: {
		getData: async function(){
			try{
				this.$emit('onLoading')

				const result = await this.Axios({
					method: 'get'
					,url: 'calculate/my'
					,data: this.search
				})

				if(result.success){
					this.items = result.data.tableList.data
					this.summary = result.data.statistics ? result.data.statistics : {}
					this.search.total_count = result.data.tableList.totalCount
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,toDetail: function(item){
			this.item = item
			this.is_modal2 = true
		}
		,reset: function(){
			this.search = {
				page: 1
				,size: this.$route.query.size ? this.$route.query.size : 10
				,search_type: ''
				,search_value: ''
				,status: ''
				,sDate: this.date.getSearchBaseDate('-')
				,eDate: this.date.getToday('-')
				,search_date_type: 'today'
			}
		}
		,getSearch: function(page){

			if(page){
				this.search.page = page
			}

			this.getData()
		}

		,toExcel: function(){
			this.excel_data.content = this.excel_list
			this.is_excel = true
		}
		,setSearchDateType: function(type){
			this.search.search_date_type = type
		}
		,close: function(){
			this.is_modal = false
			this.is_modal2 = false
			this.is_modal3 = false
			this.is_excel = false
		}
		,cancel: async function(){

		}
		, isConfirm: function(){
			if(this.select_items.length <= 0){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.not_select})
				return
			}
			if(this.item_confirm.state === ''){
				this.$emit('setNotify', { type: 'error', message: '처리상태를 선택하세요'})
			}else {

				let status_name = ''

				for(let i = 0; i < this.codes.settlement_payment_state.length; i++){
					if(this.item_confirm.state == this.codes.settlement_payment_state[i].code){
						status_name = this.codes.settlement_payment_state[i].name
					}
				}

				this.is_modal3 = true
				this.modal_option3.content = '선택한 항목을 ' + status_name + ' 처리 하시겠습니까?1'
			}
		}
		,isApproval: function(){
			if(this.select_items.length <= 0){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.not_select})
				return
			}
			if(this.item_confirm.approval === ''){
				this.$emit('setNotify', { type: 'error', message: '처리상태를 선택하세요'})
			}else {

				let status_name = ''

				for(let i = 0; i < this.codes.settlement_confirm_state.length; i++){
					if(this.item_confirm.approval == this.codes.settlement_confirm_state[i].code){
						status_name = this.codes.settlement_confirm_state[i].name
					}
				}

				this.is_modal = true
				this.modal_option.content = '선택한 항목을 ' + status_name + ' 처리 하시겠습니까?'
			}
		}
		,doConfirm: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'put'
					,url: 'calculate/branch/history'
					,data: {
						infoIdxList: this.select_items
						,state: this.item_confirm.state
						,reason: this.item_confirm.reason
					}
				})

				if(result.success){
					this.getSearch()
					this.$emit('setNotify', { type: 'success', message: this.$language.common.success})
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.close()
				this.$emit('offLoading')
			}
		}
		,setAgency: function(agency){
			this.search.branchIdx = agency.branchList
			this.search.distributorIdx = agency.distributorList
			this.search.agencyIdx = agency.agencyList
			this.search.resellerIdx = agency.resellerList
			this.getSearch(this.search.page)
		}
		,setNotify: function( { type, message }){
			this.$emit('setNotify', { type: type, message: message })
		}
		,doApproval: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'put'
					,url: 'calculate/branch/history/approval'
					,data: {
						infoIdxList: this.select_items
						,state: this.item_confirm.approval
					}
				})

				if(result.success){
					this.getSearch()
					this.$emit('setNotify', { type: 'success', message: this.$language.common.success})
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.fail})
				}
			}catch(e){
				this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				console.log(e)
			}finally {
				this.close()
				this.$emit('offLoading')
			}
		}
	}
	,created() {
		this.$emit('onLoad', this.program)
		this.getData()
	}
	,watch: {
		is_all:{
			handler: function(call){
				let self = this
				this.items.filter(function(item){
					self.$set(item, 'is_select', call)
				})
			}
		}
		,'search.search_date_type': {
			handler: function(call){
				let today = this.date.getToday('-')
				this.search.eDate = today
				switch(call){
					default: case 'today':
						this.search.sDate = today
						break
					case 'weekly':
						this.search.sDate = this.date.getLastDate(today, 7, '-')
						break
					case 'month':
						this.search.sDate = this.date.getLastDate(today, 30, '-')
						break
					case '3':
						this.search.sDate = this.date.getLastDate(today, 90, '-')
						break
					case '6':
						this.search.sDate = this.date.getLastDate(today, 180, '-')
						break
				}
			}
		}
	}
}
</script>

<style>
.bg-icon-paper { background: url('../../assets/images/icon/icon-paper.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-round-count { background: url('../../assets/images/icon/icon-round-count.svg') no-repeat 10px center; padding-left: 70px;}
.bg-icon-calc { background: url('../../assets/images/icon/icon-calc.svg') no-repeat 10px center; padding-left: 70px;}
</style>